import React from "react";
import LeftArrow from "../../components/SVGIcons/LeftArrow/LeftArrow";
import RightArrow from "../../components/SVGIcons/RightArrow/RightArrow";
import FacebookLogo from "../../components/SVGIcons/SocialMediaLogos/FacebookLogo";
import LinkedinIcon from "../../components/SVGIcons/LinkedinIcon/LinkedinIcon";
import TwitterLogo from "../../components/SVGIcons/SocialMediaLogos/TwitterLogo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ResourcesCItemType } from "../../assets/Types/AcfGroupTypes/ResourcesPg.type";
import ModalTrigger from "../../components/Buttons/ModalTrigger";
import { mxpPrimaryCtaClicked } from "../../utils/mixpanelEvents/mixpanelEvents";

import styles from "./ResourcesLayoutContainer.module.scss";
import btnStyles from "../../components/Buttons/BtnWithArrow/BtnWithArrow.module.scss";
import { resourceDownload } from "@utils/segment/resourceDownload";

type ResourcesLayoutProps = {
  ResourcesLayoutData: ResourcesCItemType;
  ResourcesLayoutTitle: string;
};

const ResourcesLayoutContainer: React.FC<ResourcesLayoutProps> = ({
  ResourcesLayoutData,
  ResourcesLayoutTitle,
}) => {
  const onClickHandler = (e: any) => {
    resourceDownload(ResourcesLayoutData.resourceCta);
    mxpPrimaryCtaClicked(e.target.href, e.target.innerText);
  };

  const downloadResourceBtn = (
    <a
      onClick={onClickHandler}
      className={`${btnStyles.btn__with_arrow} ${btnStyles.primary__blue}`}
      href={`https://spgatsby.wpengine.com${ResourcesLayoutData.resourceDownloadLink}`}
      download
    >
      {ResourcesLayoutData.resourceCta}
      <RightArrow />
    </a>
  );

  const triggerModalBtn = (
    <ModalTrigger
      href={"/"}
      modalTrigger="resource-gated"
      className={`${btnStyles.btn__with_arrow} ${btnStyles.primary__blue}`}
    >
      {ResourcesLayoutData.resourceCta}
      <RightArrow />
    </ModalTrigger>
  );

  const downloadBtn = ResourcesLayoutData.resourceDownloadLink
    ? downloadResourceBtn
    : triggerModalBtn;

  return (
    <>
      <section className={` pad-top--large-header ${styles.heroGridContainer}`}>
        <div className="full-width">
          <div className={styles.heroGrid}>
            <div>
              <a className={styles.arrowBackContainer} href="/resources/">
                <span className={styles.arrowIcon}>
                  <LeftArrow />
                </span>
                Free Resources
              </a>
              <h1>{ResourcesLayoutTitle}</h1>
              <div className={styles.hideForMediumOnly}>
                <p>{ResourcesLayoutData.resourceSubcopy}</p>
              </div>
              {downloadBtn}
            </div>

            <div className={styles.imgContainer}>
              <div>
                {ResourcesLayoutData.resourcesImage && (
                  <GatsbyImage
                    image={getImage(
                      ResourcesLayoutData.resourcesImage.localFile
                    )}
                    alt={ResourcesLayoutData.resourceCta || ""}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`full-width pad-bottom--large ${styles.classesGrid}`}>
        <div className={styles.ccontent}>
          <div
            className={styles.ccontentTable}
            dangerouslySetInnerHTML={{ __html: ResourcesLayoutData.details }}
          />
          <div className={styles.downloadBtn}>{downloadBtn}</div>

          <section className={styles.socialSharing}>
            <a
              href="https://facebook.com/sharer/sharer.php?u=https://www.simplepractice.com/private-practice/a-clinicians-guide-to-bookkeeping/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FacebookLogo cls={styles.socialSharingIcon} />
            </a>
            <a
              href="https://twitter.com/intent/tweet/?text=A Clinician’s Guide to Bookkeeping in Private Practice&amp;url=https://www.simplepractice.com/private-practice/a-clinicians-guide-to-bookkeeping/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <TwitterLogo cls={styles.socialSharingIcon} />
            </a>

            <a
              href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.simplepractice.com/private-practice/a-clinicians-guide-to-bookkeeping/&amp;title=A Clinician’s Guide to Bookkeeping in Private Practice&amp;summary=A Clinician’s Guide to Bookkeeping in Private Practice&amp;source=https://www.simplepractice.com/private-practice/a-clinicians-guide-to-bookkeeping/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedinIcon cls={styles.socialSharingIcon} />
            </a>
          </section>
        </div>
      </section>
    </>
  );
};

export default ResourcesLayoutContainer;
