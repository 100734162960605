import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import ResourcesLayoutContainer from "../../containers/ResourcesLayoutContainer/ResourcesLayoutContainer";
import ResourcesSliderLayoutContainer from "../../containers/ResourcesSliderLayoutContainer/ResourcesSliderLayoutContainer";
import {
  ResourcesCItemType,
  ResourcesTopicsType,
} from "../../assets/Types/AcfGroupTypes/ResourcesPg.type";
import { ResourcesCategoriesType } from "../../assets/Types/AcfGroupTypes/ResourcesSliderPLayout.type";
import { YoastSEOType } from "../../assets/Types";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";
type ResourcesProps = {
  wpResource: {
    id: string;
    databaseId: number;
    title: string;
    uri: string;
    resourcesC: ResourcesCItemType;
    resourcesCategories: ResourcesCategoriesType;
    resourcesTopics: ResourcesTopicsType;
    seo: YoastSEOType;
  };
};
const ResourceLayoutOne: React.FC<PageProps<ResourcesProps>> = ({ data }) => {
  const { wpResource } = data;
  const { resourcesC, resourcesCategories, title } = wpResource;

  return (
    <MainLayout hasFooterLargeCta={true}>
      {wpResource.seo && (
        <Seo post={deIndexSeo(wpResource as unknown as Queries.WpPage)} />
      )}
      <ResourcesLayoutContainer
        ResourcesLayoutData={resourcesC}
        ResourcesLayoutTitle={title}
      />
      <ResourcesSliderLayoutContainer
        resourcesCategories={resourcesCategories}
      />
    </MainLayout>
  );
};

export default ResourceLayoutOne;

export const query = graphql`
  query ResourceLayoutOneQuery($id: String!) {
    wpResource(id: { eq: $id }) {
      id
      databaseId
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      resourcesC {
        details
        resourceCta
        resourceCustomTitle
        resourceDownloadLink
        resourceSubcopy
        resourceType
        videoContent
        wistiaId
        resourcesImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [WEBP, JPG, AUTO]
              )
            }
          }
        }
      }
      resourcesCategories {
        nodes {
          id
          name
          slug
          contentNodes {
            nodes {
              ... on WpResource {
                id
                databaseId
                title
                slug
                uri
                resourcesC {
                  resourceSubcopy
                  resourcesIsHighlighted
                  resourceCta
                  resourcesCardsImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  resourcesCardsLink
                  resourceType
                  videoContent
                  wistiaId
                }
              }
            }
          }
        }
      }
      resourcesTopics {
        nodes {
          id
          name
          slug
        }
      }
    }
  }
`;
